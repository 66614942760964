const appConfig = {
  apiPrefix: 'https://api-erp.brothers.net.in/api/',
  // apiPrefix: 'https://erp.brothers.5techg.com/api/',
  // apiPrefix: 'http://localhost:5005/api/',
  authenticatedEntryPath: '/dashboard',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  locale: 'en',
  enableMock: true
}

export default appConfig
